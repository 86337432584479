import { useCallback, useMemo, useReducer } from "react";
import PropTypes from "prop-types";

import { AuthContext } from "./auth-context";
import { setSession } from "./utils";

const initialState = {
  user: null,
  loading: true,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        user: action.payload.user,
      };

    case "LOGOUT":
      return {
        ...state,
        user: null,
      };
  }
};

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  // LOGIN
  const loginSuccessful = useCallback(async (accessToken) => {
    setSession(accessToken);
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    setSession(null);
    dispatch({
      type: "LOGOUT",
    });
  }, []);

  const checkAuthenticated = state.user ? "authenticated" : "unauthenticated";

  const memorizedValue = useMemo(() => (
    {
      user: state.user,
      authenticated: checkAuthenticated === "authenticated",
      //
      loginSuccessful,
      logout,
    }), [loginSuccessful, logout, state.user]
  );

  return (
    <AuthContext.Provider value={memorizedValue}>
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.proTypes = {
  children: PropTypes.node,
};

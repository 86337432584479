import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// slices
import projects from "./slices/projects";
import userInfo from "./slices/useInfo";
import roles from "./slices/roles";
import users from "./slices/user";
import invoice from "./slices/invoice"
import reports from "./slices/reports";

// ----------------------------------------------------------------------

export const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: ["userInfo", "roles", "projects", "users", 'invoice', 'reports'], // Add the names of slices you want to persist at the root level here
};

const productPersistConfig = {
  key: "projects",
  storage,
  keyPrefix: "redux-",
  whitelist: ["checkout"],
};

export const rootReducer = combineReducers({

  // projects: projects,
  userInfo: userInfo,
  projects: persistReducer(productPersistConfig, projects),
  users: persistReducer(productPersistConfig, users),
  invoice: invoice,
  roles: roles,
  reports: reports
});

import React, { useRef, useEffect } from 'react';
import lottie from 'lottie-web';
import lottieData from '../../assets/lottie/graphs-loading.json';

export function GraphsLoading({ loading = false }) {

    const loader = useRef();

    useEffect(() => {
        lottie.loadAnimation({
            container: loader.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: lottieData,
        });
    }, []);

    return <div style={{ width: 200, margin: 'auto', height: '320px' }} ref={loader} />;
};



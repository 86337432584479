import { useMemo } from "react";
// routes
import { paths } from "../../routes/paths";
// locales
// import { useLocales } from "src/locales";
// components
// import Label from "src/components/label";
import SvgColor from "../../components/svg-color";

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  user: icon("users"),
  roles: icon("users-gear"),
  approval: icon("connection"),

};

// ----------------------------------------------------------------------

export function useNavData() {

  const data = useMemo(
    () => [
      {
        items: [
          {
            title: "Users",
            path: paths.admin.users,
            icon: ICONS.user,
            childrenPaths: [paths.admin.users, paths.admin.addUser,]
          },
          {
            title: "Roles",
            path: paths.admin.roles.list,
            icon: ICONS.roles,
            childrenPaths: [paths.admin.roles.list, paths.admin.roles.createRole]
          },
          {
            title: "Approval Flow",
            path: paths.admin.approvalFlow,
            icon: ICONS.approval,
            childrenPaths: [paths.admin.approvalFlow]
          },
        ],
      },
    ],
    []
  );

  return data;
}

import axios from 'axios';

const constructQueryParam = (filters) => {
    const queryParams = Object.entries(filters)
        .filter(([key, value]) => value !== null && value !== "" && (!Array.isArray(value) || value.length > 0))
        .map(([key, value]) => `${encodeURIComponent(key)}=${filters[key]}`)
        .join('&');
    return queryParams;
};


const preSignedUrlUpload = async (url, file, progress) => {
    const response = await axios.put(url, file, {
        headers: {
            'Content-Type': file?.type,
            'x-amz-acl': 'public-read',
        }, onUploadProgress: progress,
    });
    return response;
};

const sortingData = (property, order = 'asc') => {
    const sortOrder = order === 'desc' ? -1 : 1;

    return (a, b) => {
        const aValue = a[property];
        const bValue = b[property];

        if (aValue < bValue) {
            return -1 * sortOrder;
        } else if (aValue > bValue) {
            return 1 * sortOrder;
        } else {
            return 0;
        }
    };
};


export {
    constructQueryParam,
    preSignedUrlUpload,
    sortingData
}


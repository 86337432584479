import PropTypes from "prop-types";
import { forwardRef } from "react";
// @mui
import { useTheme } from "@mui/material/styles";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
// routes
// import { RouterLink } from 'src/routes/components';
import { Link as RouterLink } from "react-router-dom";
import { useSettingsContext } from "../settings";

// ----------------------------------------------------------------------

const LogoShort = forwardRef(({ disabledLink = false, color, sx, width = "38", height = "41", ...other }, ref) => {
    const theme = useTheme();

    const settings = useSettingsContext();

    const mode = settings.themeMode;

    const PRIMARY_LIGHT = theme.palette.primary.light;

    const PRIMARY_MAIN = color || theme.palette.primary.main;

    const PRIMARY_DARK = theme.palette.primary.dark;

    const DART = theme.palette.common.black;

    const LIGHT = theme.palette.common.white;

    // OR using local (public folder)
    // -------------------------------------------------------
    // const logo = (
    //   <Box
    //     component="img"
    //     src="/logo/logo_single.svg" => your path
    //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
    //   />
    // );

    const logo = (
        <Box
            ref={ref}
            component="div"
            sx={{
                // width: 170,
                // height: 100,
                display: "inline-flex",
                ...sx,
            }}
            {...other}
        >
            <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 18.02 21.221">
                <g id="Group_775" data-name="Group 775" transform="translate(-164.906 -29.084)">
                    <path id="Path_10" data-name="Path 10" d="M495.116,48.652a5.065,5.065,0,1,1,5.065-5.065,5.065,5.065,0,0,1-5.065,5.065m0-13.685a8.62,8.62,0,1,0,8.62,8.62,8.62,8.62,0,0,0-8.62-8.62" transform="translate(-321.59 -5.883)" fill={PRIMARY_MAIN} />
                    <path id="Path_11" data-name="Path 11" d="M509.549,60.12a2.3,2.3,0,1,0-2.3,2.3,2.3,2.3,0,0,0,2.3-2.3" transform="translate(-326.622 -12.116)" fill={PRIMARY_MAIN} />
                </g>
            </svg>
        </Box>
    );

    if (disabledLink) {
        return logo;
    }

    return (
        <Link component={RouterLink} to="/app/invoice" sx={{ display: "contents" }}>
            {logo}
        </Link>
    );
});

LogoShort.propTypes = {
    disabledLink: PropTypes.bool,
    sx: PropTypes.object,
};

export default LogoShort;

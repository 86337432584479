import { Suspense, lazy } from "react";
import { Navigate, useRoutes, Outlet } from "react-router-dom";
import MainLayout from "../layouts/main/layout";
// import LoginPage from "../container/login/Index";
import { dashboardRoutes } from "./dashboard";
import { SplashScreen } from "../components/loading-screen";
import CompactLayout from "../layouts/compact/layout";
import AuthGuard from "../auth/guard/auth-guard";
import AppGuard from "../auth/guard/app-guard";
// import ComingSoon from "../components/coming-soon";

const ComingSoon = lazy(() => import("../components/coming-soon/index"))
const InvoiceDetailsPage = lazy(() => import("../container/invoice/invoice-details-view"));
const InvoiceListPage = lazy(() => import("../container/invoice/index"));
const LoadingView = lazy(() => import("../container/others/loading-redirect"));
const LoginPage = lazy(() => import("../container/login/Index"));
const Page404 = lazy(() => import('../container/others/404-view'));
const Page500 = lazy(() => import('../container/others/500-view'));
const ProjectDashboard = lazy(() => import("../container/projects/project-dashboard"));
const ProjectDetailsPage = lazy(() => import("../container/projects/project-details-page"));
const ProjectListView = lazy(() => import("../container/projects/project-list"));
const UploadProjectList = lazy(() => import("../container/invoice/upload-project-list"));
const ReportView = lazy(() => import("../container/reports/index"));

const Router = () => {
  return useRoutes([

    { path: "/", element: <AppGuard><LoginPage /></AppGuard>, index: true },
    { path: "/redirect", element: <LoadingView /> },

    {
      path: "app",
      element: (
        <AuthGuard>
          <MainLayout>
            <Suspense fallback={<SplashScreen />}>
              <Outlet />
            </Suspense>
          </MainLayout>
        </AuthGuard>
      ),
      children: [
        { path: "invoice", element: <InvoiceListPage /> },
        { path: "invoice-details", element: <InvoiceDetailsPage /> },
        // { path: "upload-invoice", element: <InvoiceUploadPage /> },
        { path: "upload-project-list", element: <UploadProjectList /> },
        { path: "projects", element: <ProjectDashboard /> },
        { path: "project-list", element: <ProjectListView /> },
        { path: "project-details", element: <ProjectDetailsPage /> },
        { path: "coming-soon", element: <ComingSoon /> },
        { path: "reports", element: <ReportView /> },
      ],
    },

    // Dashboard routes
    ...dashboardRoutes,

    {
      element: (
        <CompactLayout>
          <Outlet />
        </CompactLayout>
      ),
      children: [
        // { path: 'coming-soon', element: <ComingSoonPage /> },
        // { path: 'maintenance', element: <MaintenancePage /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        // { path: '403', element: <Page403 /> },
      ],
    },

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
};

export default Router;

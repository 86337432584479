import React, { useState, useEffect } from 'react';
import { showSnackbar } from "../../store/slices/useInfo";
import { useDispatch } from "react-redux";

const NetworkStatusDetector = () => {
    const dispatch = useDispatch();

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarTitle, setSnackbarTitle] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // Default severity

    useEffect(() => {
        function changeStatus() {
            if (navigator.onLine) {
                setSnackbarTitle('You are back Online!');
                setSnackbarSeverity('success'); // Set severity to success
            } else {
                setSnackbarTitle('Lost Network Connection!');
                setSnackbarSeverity('warning'); // Set severity to error
            }
            setOpenSnackbar(true);
        }

        window.addEventListener('online', changeStatus);
        window.addEventListener('offline', changeStatus);

        return () => {
            window.removeEventListener('online', changeStatus);
            window.removeEventListener('offline', changeStatus);
        };
    }, []);

    useEffect(() => {
        if (openSnackbar) {
            dispatch(showSnackbar({ message: snackbarTitle, severity: snackbarSeverity }));
        }
    }, [openSnackbar, snackbarTitle, snackbarSeverity]);

    return null;
};

export default NetworkStatusDetector;

// routes
import { paths } from "../../routes/paths";
// config
// import { PATH_AFTER_LOGIN } from "src/config-global";
// components
import Iconify from "../../components/iconify";

// ----------------------------------------------------------------------

export const navConfig = [

  {
    title: "Invoices",
    icon: <Iconify icon="solar:atom-bold-duotone" />,
    path: paths.invoice.root,
    childrenPaths: [paths.invoice.root, '/app/invoice-details', '/app/upload-project-list']
  },
  {
    title: "Projects",
    icon: <Iconify icon="solar:file-bold-duotone" />,
    path: paths.project.root,
    childrenPaths: [paths.project.root, '/app/project-details']
  },
  {
    title: "Reports",
    icon: <Iconify icon="solar:notebook-bold-duotone" />,
    path: '/app/reports',
    childrenPaths: ['/app/reports']

  },
];

// @mui
import PropTypes from "prop-types";
import { Typography, Stack, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";

// component
import NavList from "./nav-list";
import Logo from '../../../../assets/logo/invoiceApp.svg'
import SvgColor from "../../../../components/svg-color";

// ----------------------------------------------------------------------

export default function NavDesktop({ offsetTop, data }) {
  const theme = useTheme()

  return (
    <Stack
      component="nav"
      direction="row"
      spacing={5}
      sx={{ mr: 2, height: 1 }}
    >
      <Stack direction="row" alignItems='center'>
        {/* <Box component="img" src={Logo} width="28px" mr={1} /> */}

        <SvgColor src={Logo} width="28px" color={theme.palette.primary.main} mr={1} />
        <Typography variant="subtitle2">IAMS</Typography>
      </Stack>
      {data.map((link) => (
        <NavList key={link.title} item={link} offsetTop={offsetTop} />
      ))}
    </Stack>
  );
}

NavDesktop.propTypes = {
  data: PropTypes.array,
  offsetTop: PropTypes.bool,
};

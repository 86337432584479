import React from "react";
import { useTheme } from "@mui/material/styles";
import {
  AppBar,
  Box,
  Button,
  Container,
  Link,
  Stack,
  Toolbar,
} from "@mui/material";
import { useBoolean } from "../../components/hook";
import { HEADER } from "../config-layout";
import { useOffSetTop, usePathname, useResponsive, useRouter } from "../../components/hook";

// theme
import { bgBlur } from "../../theme/css";
import Logo from "../../components/logo/logo";
import Label from "../../components/label";

import { navConfig } from "./config-navigation";

import NavDesktop from "./nav/desktop";
import AccountPopover from "../../components/profile/AccountPopover";
import NotificationsPopover from "../../components/notifications-popover";
import NavMobile from "./nav/mobile/nav-mobile";
import SettingsButton from "../component/settings-button";
import { HeaderShadow } from "../common";
import GridMenuPopover from "../../components/grid-menu/grid-menu-popover";
import { useDispatch, useSelector } from "../../store/store";
import { getPendingInvoiceProjects } from "../../store/slices/invoice";

const Header = () => {
  const theme = useTheme();
  const router = useRouter()
  const dispatch = useDispatch()
  const mdUp = useResponsive("up", "md");
  const offsetTop = useOffSetTop(HEADER.H_DESKTOP);
  const { userPrivileges } = useSelector(state => state.userInfo)

  const paths = ['/app/invoice']

  // Check if the user has the "Upload Invoices" privilege
  const canUploadInvoices = userPrivileges?.some(privilege => privilege.privilegeName === 'Upload Invoices');

  return (
    <AppBar>
      <Toolbar
        disableGutters
        sx={{
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_DESKTOP,
          },
          transition: theme.transitions.create(["height"], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...bgBlur({
            color: theme.palette.background.default,
          }),

          ...(offsetTop && {
            // ...bgBlur({
            //   color: theme.palette.background.default,
            // }),
            height: {
              md: HEADER.H_DESKTOP_OFFSET,
            },
          }),

        }}
      >
        <Container
          sx={{ height: 1, display: "flex", alignItems: "center" }}
          maxWidth="1900"
        >
          {mdUp && <Logo sx={{ marginRight: "2rem" }} />}

          {!mdUp && <NavMobile offsetTop={offsetTop} data={navConfig} />}

          {mdUp && <NavDesktop offsetTop={offsetTop} data={navConfig} />}

          {paths.includes(usePathname()) && canUploadInvoices &&
            <Button variant="contained" size="small" color="primary"
              onClick={() => {
                dispatch(getPendingInvoiceProjects({ records: [], metadata: null }))
                router.push('/app/upload-project-list')
              }}
              direction={{ xs: 'row', md: 'row-reverse' }}
              sx={{ ml: 2 }}
            >
              Upload Invoice
            </Button>
          }

          <Box sx={{ flexGrow: 1 }} />

          {/* <NotificationsPopover /> */}

          <AccountPopover />

          <SettingsButton />

          <GridMenuPopover />
        </Container>
      </Toolbar>
      {/* {offsetTop && <HeaderShadow />} */}
      <HeaderShadow />
    </AppBar>
  );
};

export default Header;

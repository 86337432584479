import { createSlice } from "@reduxjs/toolkit";
import client, { API_ENDPOINTS, setBaseUrl } from "../../services/apiClient"

const filters = {
    vertical: null,
    customDate: [],
    client: null
}

const initialState = {
    defaultFilters: filters,
    dateOptions: 'yearToDate',
    invoiceStatus: {
        data: null,
        loading: false
    },
    projectsStatus: {
        labels: [],
        counts: [],
        loading: false
    },
    invoiceReports: {
        data: [],
        loading: false
    },
    revenueReports: {
        data: [],
        loading: false
    },
    agingReports: {
        data: [],
        loading: false
    },
    efficiency: {
        data: [],
        loading: false
    },

}

const slice = createSlice({
    name: 'reports',
    initialState,
    reducers: {
        // getInvoice(state) {
        //     state.invoiceStatus.loading = true;
        //     state.invoiceStatus.empty = false;
        //     state.invoiceStatus.error = null;
        // },

        // INVOICE BY STATUS
        getInvoiceByStatusStart(state, action) {
            state.invoiceStatus.loading = true;
        },
        getInvoiceByStatusSuccess(state, action) {
            state.invoiceStatus.data = action.payload
            state.invoiceStatus.loading = false;
        },
        getInvoiceByStatusFailure(state, action) {
            state.invoiceStatus.loading = false;
        },

        // PROJECT BY STATUS

        getProjectByStatusStart(state, action) {
            state.projectsStatus.loading = true
        },
        getProjectByStatusSuccess(state, action) {
            state.projectsStatus.labels = action.payload.label
            state.projectsStatus.counts = action.payload.value
            state.projectsStatus.loading = false
        },
        getProjectByStatusFailure(state, action) {
            state.projectsStatus.loading = false
        },

        // INVOICE REPORTS
        getInvoiceReportsStart(state, action) {
            state.invoiceReports.loading = true
        },
        getInvoiceReportsSuccess(state, action) {
            state.invoiceReports.data = action.payload
            state.invoiceReports.loading = false
        },
        getInvoiceReportsFailure(state, action) {
            state.invoiceReports.loading = false
        },

        // INVOICE REVENUE
        getInvoiceRevenueStart(state, action) {
            state.revenueReports.loading = true
        },


        getInvoiceRevenueSuccess(state, action) {
            state.revenueReports.data = action.payload
            state.revenueReports.loading = false
        },

        getInvoiceRevenueFailure(state, action) {
            state.revenueReports.loading = false
        },

        // INVOICE AGING 
        getInvoiceAgingStart(state, action) {
            state.agingReports.loading = true
        },
        getInvoiceAgingSuccess(state, action) {
            state.agingReports.data = action.payload
            state.agingReports.loading = false
        },
        getInvoiceAgingFailure(state, action) {
            state.agingReports.loading = false
        },

        //FILTERS
        setFilterStatus(state, action) {
            state.defaultFilters = action.payload
        },

        //OPTIONS
        setDateOption(state, action) {
            state.dateOptions = action.payload
        },

        setInvoiceEfficiency(state, action) {
            state.efficiency.data = action.payload
        }


    },

    extraReducers: (builder) => {
        builder.addCase('RESET_ALL', () => initialState);  // Reset to initial state
    },
})


export default slice.reducer

export const { setFilterStatus, setDateOption } = slice.actions;





// this function is used to get invoice list
export function getInvoiceReports(vertical) {
    return async (dispatch) => {
        await setBaseUrl("invoice");
        dispatch(slice.actions.getInvoiceReportsStart());
        try {
            const response = await client.get(API_ENDPOINTS.reports.invoice(vertical));
            if (response.ok) {
                dispatch(slice.actions.getInvoiceReportsSuccess(response.data.data));
            }
        } catch (error) {
            dispatch(slice.actions.getInvoiceReportsFailure(error));
        }
    };
}

// get invoice efficiency
export function getVerticalEfficiency(vertical) {
    return async (dispatch) => {
        try {
            const response = await client.get(API_ENDPOINTS.reports.verticalEfficiency(vertical));
            if (response.ok) {
                dispatch(slice.actions.setInvoiceEfficiency(response.data.data));
            }
        } catch (error) {
            // dispatch(slice.actions.getInvoiceFailure(error));
        }
    };
}

// get invoices by stages
export function getInvoiceStatusByVerticals(vertical) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.getInvoiceByStatusStart());
            const response = await client.get(API_ENDPOINTS.reports.verticalByStatus(vertical));
            if (response.ok) {
                dispatch(slice.actions.getInvoiceByStatusSuccess(response.data.data));
            }
        } catch (error) {
            dispatch(slice.actions.getInvoiceByStatusFailure(error));
        }
    }
}

// get projects by stages
export function getProjectStatusByVerticals(vertical) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.getProjectByStatusStart());
            const response = await client.get(API_ENDPOINTS.reports.projectByStatus(vertical));
            if (response.ok) {
                dispatch(slice.actions.getProjectByStatusSuccess(response.data.data));

            }
        } catch (error) {
            dispatch(slice.actions.getProjectByStatusFailure(error));
        }
    }
}

// get revenue details by month
export function getInvoiceRevenue(vertical) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.getInvoiceRevenueStart());
            const response = await client.get(API_ENDPOINTS.reports.revenue(vertical));
            if (response.ok) {
                dispatch(slice.actions.getInvoiceRevenueSuccess(response.data.data));
            }
        } catch (error) {
            dispatch(slice.actions.getInvoiceRevenueFailure(error));
            console.log(error)
        }
    }
}


// get againg details
export function getInvoiceAgingReport(vertical) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.getInvoiceAgingStart());
            const response = await client.get(API_ENDPOINTS.reports.invoiceAging(vertical));
            if (response.ok) {
                dispatch(slice.actions.getInvoiceAgingSuccess(response.data.data));
            }
        } catch (error) {
            dispatch(slice.actions.getInvoiceRevenueFailure(error));
        }
    }
}


import React, { useState, useEffect } from 'react';

function ErrorBoundary({ children }) {
    const [hasError, setHasError] = useState(false);

    // Define the resetError function
    const resetError = () => {
        setHasError(false);
    };

    useEffect(() => {
        const errorHandler = (error, errorInfo) => {
            console.error('Error:', error);
            console.error('Error Info:', errorInfo);
            setHasError(true);
        };

        window.addEventListener('error', errorHandler);

        return () => {
            window.removeEventListener('error', errorHandler);
        };
    }, []);

    if (hasError) {
        return (
            <div>
                <h4>Something went wrong. Please try again later.</h4>
                <button onClick={resetError}>Clear Error</button>
            </div>
        );
    }

    return children;
}

export default ErrorBoundary;

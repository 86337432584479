import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "./index";
import { hideSnackbar } from "../../store/slices/useInfo";

export const SnackbarHandler = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { alertMessage } = useSelector((state) => state.userInfo);

  useEffect(() => {
    if (alertMessage.show) {
      if (alertMessage.message && alertMessage.severity) {
        enqueueSnackbar(alertMessage.message, {
          variant: alertMessage.severity,
        });
        // enqueueSnackbar('Unable to logout!',);

        // showSnackbar(snackbar.message, snackbar.severity);
        // Show the Snackbar
        dispatch(hideSnackbar()); // Hide the Snackbar after showing
      }
    }
  }, [
    alertMessage,
    enqueueSnackbar,
    dispatch,
  ]);

  return null; // This component doesn't render anything
};

import { store } from '../../store/store'
import { Auth } from "aws-amplify";
import Cookies from 'js-cookie';


// routes

import { paths } from "../../routes/paths";
import { setLogout } from '../../store/slices/useInfo';


// ----------------------------------------------------------------------

function jwtDecode(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );
  return JSON.parse(jsonPayload);
}

// ----------------------------------------------------------------------

export const isValidToken = async (accessToken) => {
  if (!accessToken) {
    return false;
  }
  const decoded = await jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;
  return decoded.exp > currentTime;
};

// ----------------------------------------------------------------------

export const tokenExpired = (exp) => {
  // eslint-disable-next-line prefer-const
  let expiredTimer;
  const currentTime = Date.now();
  // Test token expires after 10s
  // const timeLeft = currentTime + 180000 - currentTime; // ~60s
  const timeLeft = exp * 1000 - currentTime;
  clearTimeout(expiredTimer);
  if (exp) {
    expiredTimer = setTimeout(async () => {
      await store.dispatch(setLogout())
      localStorage.clear()
      Cookies.remove('token')
      store.dispatch({ type: 'RESET_ALL' });
      alert('The session has expired. Log in again');
      Auth.signOut();
      window.location.href = paths.root
    }, timeLeft);
  } else {
    clearTimeout(expiredTimer);
  }
};

// ----------------------------------------------------------------------

export const setSession = (accessToken) => {
  if (accessToken) {
    // This function below will handle when token is expired
    const { exp } = jwtDecode(accessToken); // ~3 days by minimals server
    tokenExpired(exp);
  }
};

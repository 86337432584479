import PropTypes from "prop-types"; // @mui
import { Box } from "@mui/material";

import { useSettingsContext } from "../../components/settings/context";
import { useResponsive } from "../../components/hook";

import { HEADER, NAV } from "../config-layout";

const SPACING = 8;

const Main = ({ children, sx, ...other }) => {
  const settings = useSettingsContext();
  //   console.log(settings);

  const lgUp = useResponsive("up", "lg");

  const isNavHorizontal = settings.themeLayout === "horizontal";

  const isNavMini = settings.themeLayout === "mini";

  if (isNavHorizontal) {
    return (
      <Box
        component="main"
        sx={{
          minHeight: 1,
          display: "flex",
          flexDirection: "column",
          pt: `${HEADER.H_MOBILE + 24}px`,
          pb: 10,
          ...(lgUp && {
            pt: `${HEADER.H_MOBILE * 2 + 40}px`,
            pb: 15,
          }),
        }}
      >
        {children}
      </Box>
    );
  }

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        pb: 1.4,
        pt: `${HEADER.H_MOBILE + SPACING}px`,
        ...(lgUp && {
          px: 0,
          pt: `${HEADER.H_DESKTOP + SPACING}px`,
          width: `calc(100% - ${NAV.W_VERTICAL}px)`,
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_MINI}px)`,
          }),
        }),
        ...sx,
      }}
      {...other}
    >
      {children}
    </Box>
  );
};

Main.prototype = {
  children: PropTypes.node,
};
export default Main;

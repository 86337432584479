
import { create, CancelToken } from "apisauce";
import Cookies from 'js-cookie';
import { constructQueryParam } from "../utils";
import { isValidToken } from "../auth/context/utils";
import { Auth } from "aws-amplify";
import axios from 'axios';


import { store } from '../store/store'
import { setLogout, showSnackbar } from "../store/slices/useInfo";
const token = Cookies.get("token")

const apiClient = create({
  baseURL: "",
  timeout: 15000,
  headers: {
    "ngrok-skip-browser-warning": "any",
    Accept: "application/json",
    "Content-Type": "application/json",
    Pragma: "no-cache",
    "Cache-Control": "no-cache",
    Authorization: `Bearer ${token}`,
  },
});

// apiClient.addAsyncRequestTransform((request) => async () => {
//   const user = await store.getState()
//   const isValid = await isValidToken(token) // used for validate the current token 
//   console.log(user?.userInfo?.isLogin, isValid)
//   if (user?.userInfo?.isLogin && !isValid && request.url !== '/user/login') {
//     alert('Session has been expired please re-login again');
//     Cookies.remove('token');
//     window.location.href = '/'
//   }  
// })

apiClient.addResponseTransform(async (response) => {
  if (response.status === 500) {
    await store.dispatch(showSnackbar({
      message: response?.data?.message,
      severity: "error",
      autoHideDuration: 6000
    }));
  }
})


function getDevelopmentBaseUrl(endpoint) {
  switch (endpoint) {
    case "invoice":
      return process.env.REACT_APP_API_URL_INVOICE;
    case "user":
      return process.env.REACT_APP_API_URL_USER;
    case "rbac":
      return process.env.REACT_APP_API_URL_RBAC;
    default:
      return "";
  }
}

export function setBaseUrl(endpoint) {
  const url = getDevelopmentBaseUrl(endpoint);
  apiClient.setBaseURL(url);
}

export default apiClient;

export const API_ENDPOINTS = {
  login: `/user/login`,
  userInfo: '/user/privilege',
  projects: {
    list: (sort, sortOrder, search, page, pageCount, status, vertical, client, pm) =>
      (`/project/list?sort=${sort}&sortOrder=${sortOrder}&search=${search}&page=${page}&pageCount=${pageCount}&status=${status}&vertical=${vertical}&client=${client}&project=${pm}`),
    details: (code) => `/project?projectCode=${code}`,
    projectTable: (filters) => `/project/invoice/list?${constructQueryParam(filters)}`,
    counts: `/project/statusCount`,
    staticsCount: `/project/dashboard`,
    verticals: `/project/dashboard/vertical`,
    verticalLookUp: (type) => `lookup/verticleLookUp?verticalType=${type}`,
    approval: `/project/approvalFlow`,
    deleteInvoiceMonth: (invoiceID) => `/invoice/closed?invoiceClosedId=${invoiceID}`,
    getReasonsLookup: (key) => `/lookUp?key=${key}`,
  },

  roles: {
    defaultRoles: `/role/list`,
    customList: (sort, sortOrder, search, page, pageCount) =>
      `/role/list?roleType=custom&sort=${sort}&sortOrder=${sortOrder}&search=${search}&page=${page}&pageCount=${pageCount}`,
    privilege: `/role/appPrivilege`,
    delete: (roleId) => `/role/${roleId}`,
    create: "/role/info",
    getById: (id) => `role/${id}/info`,
    update: (id) => `role/${id}/info`,

  },

  users: {
    list: (sort, sortOrder, search, page, pageCount) =>
      `/user/list?sort=${sort}&sortOrder=${sortOrder}&search=${search}&page=${page}&pageCount=${pageCount}`,
    put: (userId, status) => `/user/${userId}/status?status=${status}`,
    create: "/user/info",
    getLookup: (key) => `/lookUp?key=${key}`,
    getRoleById: (id) => `/role/${id}/info`,
    update: (id) => `/user/${id}/info`,
    getUserById: (id) => `/user/role/${id}/info`,
    addUserPath: `/auth/add-user`,
    getPrivilageFilter: (key) => `lookUp?key=${key}`,
    getverticals: (key) => `lookUp?key=${key}`,
    profile: (id) => `/user/${id}/profile`,

  },

  invoice: {
    list: (filters) => `/invoice/list?${constructQueryParam(filters)}`,
    projectList: (filters) => `/invoice/project/list?${constructQueryParam(filters)}`,
    // projectList: (sort, sortOrder, search, page, pageCount, verticals, clients, projects, fromMonth, fromYear) =>
    //   `/invoice/project/list?sort=${sort}&sortOrder=${sortOrder}&search=${search}&page=${page}&pageCount=${pageCount}&vertical=${verticals}&client=${clients}&project=${projects}&fromMonth=${fromMonth}&fromYear=${fromYear}`,
    getClients: `/lookUp/client`,
    getVerticals: `/lookUp/verticle`,
    getProjects: `/lookUp/project`,
    getProjectManagers: `/lookUp/projectManager`,
    getProjectsLookUpByCode: (key, code) => `/lookup?key=${key}&clientCode=${code}`,
    uploadFiles: `/invoice`,
    dashboardData: (filter) => `/invoice/dashBoard?${constructQueryParam(filter)}`,
    statusCount: `/invoice/statusCount`,
    getApprovalUser: (code) => `/project/invoice/approver?projectCode=${code}`,
    getRevisionApprover: (id) => `/invoice/approver?invoiceId=${id}`,
    getAdditionalUser: (code) => `/project/lookup/user?projectCode=${code}`,
    invoiceInfo: (id, code) => `/invoice/${id}/info?projectCode=${code}`,
    invoiceApprovalStatus: (invoiceId) => `invoice/approvalStatus?invoiceId=${invoiceId}`,
    putApprovalStatus: (invoiceVersionId) => `invoice/approver/status?invoiceVersionId=${invoiceVersionId}`,
    sendInvoice: (invoiceId, projectCode) => `/invoice/mail?invoiceId=${invoiceId}&projectCode=${projectCode}`,
    markAsPaid: (invoiceId, projectCode) => `/invoice/mark/paid?invoiceId=${invoiceId}&projectCode=${projectCode}`,
    revision: `/invoice/revision`,
    getInvoiceDescription: `invoice/mail`,
    notifyVH: `/invoice/notify/mail`,
    closeMonth: '/invoice/closed'
  },
  getLookup: (key) => `/lookUp?key=${key}`,

  reports: {
    invoice: (vertical) => `/report/invoice?${constructQueryParam(vertical)}`,
    verticalEfficiency: (vertical) => `/report/invoice/vertical/efficiency?${constructQueryParam(vertical)}`,
    verticalByStatus: (vertical) => `/report/invoice/vertical?${constructQueryParam(vertical)}`,
    projectByStatus: (vertical) => `/report/project/vertical?${constructQueryParam(vertical)}`,
    revenue: (vertical) => `/report/invoice/revenue?${constructQueryParam(vertical)}`,
    invoiceAging: (vertical) => `/report/invoice/aging?${constructQueryParam(vertical)}`,

  }
};


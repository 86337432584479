import React, { useEffect } from "react";
import { Amplify, Auth } from "aws-amplify";
import MotionLazy from "./components/animation/motion-lazy";
import Cookies from 'js-cookie';

// scroll bar
import "simplebar-react/dist/simplebar.min.css";


import { AuthProvider } from "./auth/context";
import { SettingsDrawer } from "./components/settings";
import { SettingsProvider } from "./components/settings/context";
import { SnackbarHandler, SnackbarProvider } from "./components/snackbar";
import ProgressBar from './components/progress-bar'
import Router from "./routes";
import ThemeProvider from "./theme";
import ReduxProvider from "./store/redux-provider";

import { useScrollToTop } from './components/hook';
import NetworkStatusDetector from "./components/network/network-status"
import ErrorBoundary from "./components/error-boundary";

import { cognitoConfig } from "./aws-config";
import { setLogout } from './store/slices/useInfo';

import { store } from './store/store'

function App() {

  useScrollToTop();
  Amplify.configure({
    Auth: cognitoConfig
  });

  function handleUnload() {
    console.log("unload")
    localStorage.clear()
    Cookies.remove('token')
    store.dispatch(setLogout());
    window.location.href = '/'
  }

  // useEffect(() => {
  //   window.addEventListener('unload', handleUnload);
  //   // Don't forget to remove the event listener when the component unmounts
  //   return () => {
  //     window.removeEventListener('unload', handleUnload);
  //   };
  // }, []);

  return (
    <ErrorBoundary>
      <AuthProvider>
        <ReduxProvider>
          <SettingsProvider
            defaultSettings={{
              themeMode: "light", // 'light' | 'dark'
              themeDirection: "ltr", //  'rtl' | 'ltr'
              themeContrast: "default", // 'default' | 'bold'
              themeLayout: "vertical", // 'vertical' | 'horizontal' | 'mini'
              themeColorPresets: "default", // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
              themeStretch: false,
            }}
          >
            <ThemeProvider>
              <MotionLazy>
                <SnackbarProvider>
                  <NetworkStatusDetector />
                  <ProgressBar />
                  <SettingsDrawer />
                  <Router />
                  <SnackbarHandler />
                </SnackbarProvider>
              </MotionLazy>
            </ThemeProvider>
          </SettingsProvider>
        </ReduxProvider>
      </AuthProvider>
    </ErrorBoundary>
  );
}

export default App;

import { m } from "framer-motion";

import {
  Avatar,
  Box,
  Divider,
  IconButton,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { varHover } from "../animation/variants";
import CustomPopover from "../custom-popover/custom-popover";
import { usePopover } from "../custom-popover";
import { paths } from "../../routes/paths";
import { useRouter } from "../hook";
import Iconify from "../iconify/iconify";
import BaseOptions from "../settings/drawer/base-option";


const GridMenuPopover = () => {
  const popover = usePopover();
  const router = useRouter();

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={popover.onOpen}
        color={popover.open ? "primary" : "default"}
        // onClick={drawer.onTrue}
        size="small"
        sx={{ marginX: 1 }}
      >
        <Iconify icon='gg:menu-grid-r' />

      </IconButton>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        sx={{ width: 250, p: 0 }}
      >


        <Box sx={{ p: 2, pb: 1.5 }}>
          <Typography variant="subtitle2" noWrap gutterBottom>
            Apps
          </Typography>

          <BaseOptions
            value={'Invoice'}
            onChange={(newValue) => console.log(newValue)}
            options={["Invoice", "Expense"]}
            icons={["invoice_app", "app-invoice-dollar",]}
            names={["IAMS", "Coming soon"]}
          />

        </Box>


      </CustomPopover>
    </>
  );
};

export default GridMenuPopover;

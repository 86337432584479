import { createSlice } from "@reduxjs/toolkit";

import client, { API_ENDPOINTS, setBaseUrl } from "../../services/apiClient";

const defaultState = {
  users: [],
  usersMeta: null,
  usersStatus: {
    loading: false,
    empty: false,
    error: null,
  },
  lookUp: {
    verticals: [],
    projects: []
  },
  userStatusUpdate: {
    loading: false,
    error: null,
  },
  rolesLookup: [],
  putUsersStatus: "",
  userFormMode: "CREATE",
};

const slice = createSlice({
  name: "users",
  initialState: defaultState,
  reducers: {
    // GET USERS
    getUsersStart(state) {
      state.usersStatus.loading = true;
      state.usersStatus.empty = false;
      state.usersStatus.error = null;
    },
    getRolesFailure(state, action) {
      state.usersStatus.loading = false;
      state.usersStatus.empty = false;
      state.usersStatus.error = action.payload;
    },
    getUsersSuccess(state, action) {
      const users = action.payload.records;
      state.users = users;
      state.usersMeta = action.payload.metadata;
      state.usersStatus.loading = false;
      state.usersStatus.empty = !users.length;
      state.usersStatus.error = null;
    },
    setSortingUsersData(state, action) {
      state.users = action.payload;
    },

    getUsersFailure(state, action) {
      state.users = [];
      state.usersStatus.loading = false;
    },

    //UPDATE USER STATUS
    getRoleByIdFailure(state, action) {
      state.RolesById.loading = false;
      state.RolesById.empty = false;
      state.RolesById.error = action.payload;
    },
    getRoleSuccess(state, action) {
      state.rolesLookup = action.payload;
    },

    setUserFormType(state, action) {
      state.userFormMode = action.payload;
    },
    getLookUpData(state, action) {
      state.lookUp.projects = action.payload.projects;
      state.lookUp.verticals = action.payload.verticals
    }
  },
});

export default slice.reducer;

// ----------------------------------------------------------------------

// this function is used to get users list
export function getUsersList(sort, sortOrder, search, page, pageCount) {
  return async (dispatch) => {
    await setBaseUrl("user");
    await dispatch(slice.actions.getUsersStart());
    try {
      const response = await client.get(
        API_ENDPOINTS.users.list(sort, sortOrder, search, page, pageCount)
      );
      dispatch(slice.actions.getUsersSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.getUsersFailure(error));
    }
  };
}

export function getRoleLookup() {
  return async (dispatch) => {
    // Assuming that setBaseUrl and client are imported correctly
    await setBaseUrl("user");
    try {
      // Assuming that API_ENDPOINTS and users.getLookup("role") are defined correctly
      const response = await client.get(API_ENDPOINTS.users.getLookup("role"));
      dispatch(slice.actions.getRoleSuccess(response?.data?.data?.role));
    } catch (error) {
      dispatch(slice.actions.getRolesFailure(error));
    }
  };
}

export function getLookUpData(key) {
  return async (dispatch) => {
    const response = await client.get(API_ENDPOINTS.users.getLookup(key));
    if (response.ok) {
      await dispatch(slice.actions.getLookUpData(response.data.data))
    }
  }
};


export const postUserCreation = (data) =>
  client.post(API_ENDPOINTS.users.create, data);

export const getUserById = (id) =>
  client.get(API_ENDPOINTS.users.getUserById(id));

export const getPrivilageFilter = (key) =>
  client.get(API_ENDPOINTS.users.getPrivilageFilter(key));

export const getverticals = (key) =>
  client.get(API_ENDPOINTS.users.getverticals(key));

export const getProjectsLookUp = (key) =>
  client.get(API_ENDPOINTS.users.getLookup(key));

export const updateUserById = (id, data) =>
  client.put(API_ENDPOINTS.users.update(id), JSON.stringify(data));

export const updateStatus = (id, status) =>
  client.put(API_ENDPOINTS.users.put(id, status));

export const getRolesById = (guId) =>
  client.get(API_ENDPOINTS.users.getRoleById(guId));

export const updateUserProfile = (id, data) => client.put(API_ENDPOINTS.users.profile(id), data);

// export const Login = (email) => client.post(API_ENDPOINTS.users.login(email));

export const { setUserFormType, setSortingUsersData } = slice.actions;

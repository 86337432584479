import { Box } from "@mui/material";
import Main from "./main";

import Header from "./header";
import { useBoolean, useResponsive } from "../../components/hook";

import NavVertical from "./nav-vertical";
import { useSettingsContext } from "../../components/settings";
import NavMini from "./nav-mini";

const DashboardLayout = ({ children }) => {
  const settings = useSettingsContext();
  //   console.log(settings);
  const nav = useBoolean();

  const lgUp = useResponsive("up", "lg");

  const isHorizontal = settings.themeLayout === "horizontal";

  const isMini = settings.themeLayout === "mini";

  const renderNavVertical = (
    <NavVertical openNav={nav.value} onCloseNav={nav.onFalse} />
  );

  const renderNavMini = <NavMini />;

  if (isMini) {
    return (
      <>
        <Header onOpenNav={nav.onTrue} />

        <Box
          sx={{
            display: { lg: "flex" },
            minHeight: { lg: 1 },
          }}
        >
          {lgUp ? renderNavMini : renderNavVertical}

          <Main>{children}</Main>
        </Box>
      </>
    );
  }

  return (
    <>
      <Header onOpenNav={nav.onTrue} />
      <Box
        sx={{
          display: { lg: "flex" },
          minHeight: { lg: 1 },
        }}
      >
        {renderNavVertical}
        <Main>{children}</Main>
      </Box>
    </>
  );
};
export default DashboardLayout;

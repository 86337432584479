import { m } from "framer-motion";
import { alpha } from "@mui/material/styles";

import { makeStyles } from "@mui/styles";
import { useEffect } from 'react'
import {
  Avatar,
  Button,
  Box,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import Cookies from 'js-cookie';
import { Auth } from "aws-amplify";


import { varHover } from "../animation/variants";
import CustomPopover from "../custom-popover/custom-popover";
import { usePopover } from "../custom-popover";
import { usePathname, useRouter } from "../hook";
import { useAuthContext } from "../../auth/hooks";
import { useSettingsContext } from "../settings/context";
import { useDispatch, useSelector } from "../../store/store";
import { setLogout } from "../../store/slices/useInfo";

const AccountPopover = () => {
  const classes = useStyles();
  const popover = usePopover();
  const router = useRouter();
  const settings = useSettingsContext()
  const dispatch = useDispatch();
  const consolePath = ['/auth/users', '/auth/add-user', '/auth/roles', '/auth/create-role', '/auth/approval-flow']
  const pathName = usePathname()

  const { user, role } = useSelector(state => state.userInfo)
  const { logout } = useAuthContext();


  const handleLogout = async () => {
    popover.onClose()
    router.push("/");
    logout()
    dispatch(setLogout())
    dispatch({ type: 'RESET_ALL' });
    // Broadcast the logout event to other tabs
    const channel = new BroadcastChannel('user-logout');
    channel.postMessage({ logout: true });
    await Auth.signOut();

  };

  const handleLogoutEvent = async (event) => {
    if (event.data.logout) {
      // Clear user data in this tab if a logout event is received
      dispatch(setLogout())
      Cookies.remove('token')
      await Auth.signOut();
      router.push("/");
    }
  };

  useEffect(() => {
    // Listen for logout events from other tabs
    // const userLoginChannel = new BroadcastChannel('user-login');
    const userLogoutChannel = new BroadcastChannel('user-logout');

    // userLoginChannel.addEventListener('message', handleLoginEvent);
    userLogoutChannel.addEventListener('message', handleLogoutEvent);
    return () => {
      // Cleanup event listeners
      // userLoginChannel.removeEventListener('message', handleLoginEvent);
      userLogoutChannel.removeEventListener('message', handleLogoutEvent);
    };
  }, []);


  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={popover.onOpen}
        sx={{
          width: 40,
          height: 40,
          background: (theme) => alpha(theme.palette.grey[500], 0.08),
          ...(popover.open && {
            background: (theme) =>
              `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
          }),
        }}
      >
        <Avatar
          src={user?.profileImage !== "" ? user?.profileImage : user?.firstName}
          alt={user?.firstName.toUpperCase()}
          sx={{
            width: 36,
            height: 36,
            border: (theme) => `solid 2px ${theme.palette.background.default}`,
          }}
        />
      </IconButton>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        sx={{ width: 250, p: 0, }}
      >
        <Stack sx={{ p: 2, }} alignItems='center'>
          <Avatar
            src={user?.profileImage !== "" ? user?.profileImage : user?.firstName}
            alt={user?.firstName.toUpperCase()}
            sx={{
              width: 86,
              height: 86,
              border: (theme) => `solid 2px ${theme.palette.background.default}`,
              zIndex: 1,
              background: '#eaeaeb',
              fontSize: '2rem'
            }}
          />

        </Stack>

        <Box className={classes.container}
          sx={{
            p: 2,
            pt: 5.3,
            ...(settings.themeMode === 'light' && {
              background: '#F1FCEE'
            })
          }}
        >

          <Typography color="primary" align="center" gutterBottom sx={{ mt: -2 }}>
            {role?.name}
          </Typography>

          <Typography variant="subtitle2" noWrap gutterBottom>
            {user?.firstName}
          </Typography>

          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap gutterBottom >
            {user?.email}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }} gutterBottom >
            GAID : {user?.gaid}
          </Typography>

          <Box my={2} >
            {
              (role?.name === "Super Admin" || role?.name === "Admin") &&
              <Button variant="outlined" size='small'
                onClick={(e) => {
                  popover.onClose(e)
                  router.push(!consolePath.includes(pathName) ? "/auth/users" : '/app/invoice');
                }}
                sx={{ mr: 2, background: settings.themeMode === 'light' ? '#fff' : '#212B35' }}
              >
                {!consolePath.includes(pathName) ? 'Admin Console' : 'Dashboard'}
              </Button>
            }

            <Button variant="outlined" size='small'
              color="inherit"
              onClick={handleLogout}
              sx={{ background: settings.themeMode === 'light' ? '#fff' : '#212B35' }}
            >
              Logout
            </Button>
          </Box>
        </Box>
      </CustomPopover>
    </>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    width: '100 %',
    borderTop: '1px solid #74C061',
    position: 'relative',
    marginTop: '-2.6rem',
  },
  halfCircle: {
    width: 110,
    height: '44px',
    position: 'absolute',
    left: '50%',
    top: '-1px',
    transform: 'translateX(-50%)',
    borderBottom: '1px solid #74C061',
    borderLeft: '1px solid #74C061',
    borderRight: '1px solid #74C061',
    borderBottomLeftRadius: '200px',
    borderBottomRightRadius: '200px',
    background: '#fff'
  }

}));

export default AccountPopover;

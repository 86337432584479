import { Box } from "@mui/material";
import PropTypes from "prop-types";
import Header from "./header";
// import { usePathname } from "../../components/hook";

const MainLayout = ({ children }) => {
  // const pathname = usePathname();

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: 1 }}>
      <Header />

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          // ...(!isHome && {
          pt: { xs: 10, md: 12 },
          pb: 3,
          // }),
        }}
      >
        {children}
      </Box>

      {/* <Footer /> */}
    </Box>
  );
};

MainLayout.prototype = {
  children: PropTypes.node,
};

export default MainLayout;

import PropTypes from "prop-types";
import { useEffect, useCallback, useState } from "react";

import { useSelector } from "../../store/store";
import { useRouter } from "../../components/hook";

export default function DashboardGuard({ children }) {
    const roles = ['Admin', 'Super Admin']
    const router = useRouter();

    const { role } = useSelector(state => state.userInfo)

    const checkIsAccess = useCallback(() => {

        if (!roles.includes(role.name)) {
            router.push('/404')
        }

    }, [router]);

    useEffect(() => {
        checkIsAccess();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <>{children}</>;
}


DashboardGuard.propTypes = {
    children: PropTypes.node,
};
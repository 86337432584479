// utils
// import { paramCase } from "src/utils/change-case";
// import { _id, _postTitles } from 'src/_mock/assets';

// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: "/auth",
  DASHBOARD: "/app",
};

// ----------------------------------------------------------------------

export const paths = {
  root: '/',
  projects: `${ROOTS.DASHBOARD}/projects`,
  users: "/auth/users",
  roles: "auth/roles",

  comingSoon: "/coming-soon",
  maintenance: "/maintenance",
  payment: "/payment",

  page403: "/403",
  page404: "/404",
  page500: "/500",
  components: "/components",
  // INVOICE
  invoice: {
    root: `${ROOTS.DASHBOARD}/invoice`,
    new: `${ROOTS.DASHBOARD}/invoice/new`,
    // details: (id) => `${ROOTS.DASHBOARD}/invoice-details?id=${id}`,
    edit: (id) => `${ROOTS.DASHBOARD}/invoice/${id}/edit`,
    projects: `${ROOTS.DASHBOARD}/upload-project-list`,
    uploadInvoice: `${ROOTS.DASHBOARD}/upload-invoice`,
    details: (id, code) => `${ROOTS.DASHBOARD}/invoice-details?invoice_id=${id}&project_code=${code}`
  },
  // PROJECT
  project: {
    root: `${ROOTS.DASHBOARD}/projects`,
    // list: `${ROOTS.DASHBOARD}/project-list`,
    edit: `${ROOTS.DASHBOARD}/project-edit`,
    details: (id) => `${ROOTS.DASHBOARD}/project-details?id=${id}`,
    projectDetails: (id) => `${ROOTS.DASHBOARD}/project-details?project_code=${id}`
  },

  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/`,
    },
  },

  // DASHBOARD
  dashboard: {
    root: `${ROOTS.DASHBOARD}/dashboard`,
    invoice: {
      root: `${ROOTS.DASHBOARD}/invoice`,
      new: `${ROOTS.DASHBOARD}/invoice/new`,
      details: (id) => `${ROOTS.DASHBOARD}/invoice-details?id=${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/invoice/${id}/edit`,
    },
  },

  admin: {
    users: `${ROOTS.AUTH}/users`,
    addUser: `${ROOTS.AUTH}/add-user`,
    roles: {
      list: `${ROOTS.AUTH}/roles`,
      createRole: `${ROOTS.AUTH}/create-role`,
    },
    approvalFlow: `${ROOTS.AUTH}/approval-flow`,
  },
};

import PropTypes from "prop-types"; // @mui
import Collapse from "@mui/material/Collapse";
import { listClasses } from "@mui/material/List";
import { listItemTextClasses } from "@mui/material/ListItemText";
import { listItemButtonClasses } from "@mui/material/ListItemButton";
// hooks
import { usePathname, useBoolean } from "../../../../components/hook";

// components
import { NavSectionVertical } from "../../../../components/nav-section";
//
import NavItem from "./nav-item";

// ----------------------------------------------------------------------

export default function NavList({ item }) {
  const pathname = usePathname();

  const { path, children, childrenPaths } = item;

  const externalLink = path.includes("http");

  const active = childrenPaths?.includes(pathname) || false;


  const nav = useBoolean();

  return (
    <>
      <NavItem
        item={item}
        open={nav.value}
        onClick={nav.onToggle}
        active={active}
        externalLink={externalLink}
      />

      {!!children && (
        <Collapse in={nav.value} unmountOnExit>
          <NavSectionVertical
            data={children}
            sx={{
              [`& .${listClasses.root}`]: {
                "&:last-of-type": {
                  [`& .${listItemButtonClasses.root}`]: {
                    height: 160,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    bgcolor: "background.neutral",
                    backgroundRepeat: "no-repeat",
                    backgroundImage:
                      "url(/assets/illustrations/illustration_dashboard.png)",
                    [`& .${listItemTextClasses.root}`]: {
                      display: "none",
                    },
                  },
                },
              },
            }}
          />
        </Collapse>
      )}
    </>
  );
}

NavList.propTypes = {
  item: PropTypes.object,
};

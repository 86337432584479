import { createSlice } from '@reduxjs/toolkit'
import client, { API_ENDPOINTS, setBaseUrl } from "../../services/apiClient"

const slice = createSlice({
    name: "roles",
    initialState: {
        defaultRoles: [],
        customRoles: [],
        customRolesMeta: null,
        privileges: [],
        roleInfo: {
            loading: false,
            data: null
        },
        roleFormMode: "CREATE",
        rolesStatus: {
            loading: false,
            empty: false,
            error: null,
        },
        customRolesStatus: {
            loading: false,
            empty: false,
            error: null,
        },
    },
    reducers: {
        // GET DEFAULT ROLES
        getRolesStart(state) {
            state.rolesStatus.loading = true;
            state.rolesStatus.empty = false;
            state.rolesStatus.error = null;
        },
        getRolesFailure(state, action) {
            state.rolesStatus.loading = false;
            state.rolesStatus.empty = false;
            state.rolesStatus.error = action.payload;
        },
        getRolesSuccess(state, action) {
            const defaultRoles = action.payload;
            state.defaultRoles = defaultRoles;
            state.rolesStatus.loading = false;
            state.rolesStatus.empty = !defaultRoles.length;
            state.rolesStatus.error = null;
        },

        // GET CUSTOM ROLES
        getCustomRolesStart(state) {
            state.customRolesStatus.loading = true;
            state.customRolesStatus.empty = false;
            state.customRolesStatus.error = null;
        },
        getCustomRolesFailure(state, action) {
            state.customRolesStatus.loading = false;
            state.customRolesStatus.empty = false;
            state.customRolesStatus.error = action.payload;
        },
        getCustomRolesSuccess(state, action) {
            const customRoles = action.payload.records;
            state.customRoles = customRoles;
            state.customRolesMeta = action.payload.metadata;
            state.customRolesStatus.loading = false;
            state.customRolesStatus.empty = !customRoles.length;
            state.customRolesStatus.error = null;
        },
        setSortingCustomRolesData(state, action) {
            state.customRoles = action.payload;
        },

        // getPrivileges
        getPrivilegesSuccess(state, action) {
            state.privileges = action.payload;
        },
        // getRole by ID
        getRoleByIdSuccess(state, action) {
            state.roleInfo.data = action.payload;
            state.roleInfo.loading = false;
        },

        // CREATE NEW ROLE
        postCreateRoleStart(state) {
            state.createRoleStatus.loading = true;
            state.createRoleStatus.error = false;
        },

        postCreateRoleFailure(state, action) {
            console.log(action)
            state.createRoleStatus.loading = false;
            state.createRoleStatus.error = true;
        },

        postCreateRoleSuccess(state) {
            state.createRoleStatus.loading = false;
            state.createRoleStatus.error = false;
        },

        // HANDLE ROLE FORM TYPE

        setRoleFormType(state, action) {
            state.roleFormMode = action.payload;
        }
    }

})

export const { setRoleFormType, setSortingCustomRolesData } = slice.actions;


export default slice.reducer

// ----------------------------------------------------------------------

// this function is used to get default roles list
export function getDefaultRoles() {
    return async (dispatch) => {
        await setBaseUrl('rbac')
        dispatch(slice.actions.getRolesStart());
        try {
            const response = await client.get(API_ENDPOINTS.roles.defaultRoles)

            dispatch(slice.actions.getRolesSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.getRolesFailure(error));
        }
    };
}

// ----------------------------------------------------------------------

// this function is used to get custom roles list
export function getCustomRoles(sort, sortOrder, search, page, pageCount) {
    return async (dispatch) => {
        await setBaseUrl('rbac')
        dispatch(slice.actions.getCustomRolesStart());
        try {
            const response = await client.get(API_ENDPOINTS.roles.customList(sort, sortOrder, search, page, pageCount))
            dispatch(slice.actions.getCustomRolesSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.getCustomRolesFailure(error));
        }
    };
}

// this function is used to get privilege roles list
export function getPrivileges() {
    return async (dispatch) => {
        await setBaseUrl('rbac')
        try {
            const response = await client.get(API_ENDPOINTS.roles.privilege)
            dispatch(slice.actions.getPrivilegesSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.getCustomRolesFailure(error));
        }
    };
}

// ----------------------------------------------------------------------

export const postRoleCreation = (data) => client.post(API_ENDPOINTS.roles.create, JSON.stringify(data));

export const updateCustomRole = (id, data) => client.put(API_ENDPOINTS.roles.update(id), JSON.stringify(data));

export const deleteRole = (id) => client.delete(API_ENDPOINTS.roles.delete(id))

export const getRoleById = (id) => client.get(API_ENDPOINTS.roles.getById(id))
import { Outlet } from "react-router-dom";
import { Suspense, lazy } from "react";

import { SplashScreen } from "../components/loading-screen";
import AuthGuard from "../auth/guard/auth-guard";
import DashboardGuard from "../auth/guard/dashboard-guard";
import DashboardLayout from "../layouts/dashboard/layout";

const AddUserPage = lazy(() => import("../container/adminConsole/users/add-user"));
const ApprovalFlow = lazy(() => import("../container/adminConsole/approval-flow/approval-flow"))
const CreateRole = lazy(() => import("../container/adminConsole/roles/create-role"));
const IndexPage = lazy(() => import("../container/adminConsole/users/index"));
const RoleListPage = lazy(() => import("../container/adminConsole/roles/role-list"));
const TableSkeleton = lazy(() => import("../components/skeleton/table-skeleton"));
const UserInfoPage = lazy(() => import("../container/adminConsole/users/user-info"));

export const dashboardRoutes = [
  {
    path: "auth",
    element: (
      <AuthGuard>
        <DashboardGuard>
          <DashboardLayout>
            <Suspense fallback={<SplashScreen />}>
              <Outlet />
            </Suspense>
          </DashboardLayout>
        </DashboardGuard>
      </AuthGuard>
    ),
    children: [
      { path: "users", element: <IndexPage /> },
      { path: "roles", element: <RoleListPage /> },
      { path: "roles-list", element: <RoleListPage /> },
      { path: "add-user", element: <AddUserPage /> },
      { path: "user-info", element: <UserInfoPage /> },
      { path: "create-role", element: <CreateRole /> },
      { path: "skeleton", element: <TableSkeleton /> },
      { path: "approval-flow", element: <ApprovalFlow /> }
    ]
  },
];

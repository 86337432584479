import { useTheme } from "@mui/material/styles";
import { AppBar, Button, IconButton, Stack, Toolbar } from "@mui/material";
// theme
import { bgBlur } from '../../theme/css';
// hooks
import { useOffSetTop, useResponsive } from "../../components/hook";

// components
import Logo from "../../components/logo/logo";
import SvgColor from "../../components/svg-color";
import { useSettingsContext } from "../../components/settings/context";
import MenuIcon from "../../assets/icons/nav/ic_menu_item.svg";
import AccountPopover from "../../components/profile/AccountPopover";
import SettingsButton from "../component/settings-button";
import { HEADER, NAV } from "../config-layout";

const Header = ({ onOpenNav }) => {
  const theme = useTheme();

  const settings = useSettingsContext();

  const lgUp = useResponsive("up", "lg");

  const isNavHorizontal = settings.themeLayout === "horizontal";

  const isNavMini = settings.themeLayout === "mini";

  const offset = useOffSetTop(HEADER.H_DESKTOP);

  const offsetTop = offset && !isNavHorizontal;

  const renderContent = (
    <>
      {lgUp && isNavHorizontal && <Logo sx={{ mr: 2.5 }} />}

      {!lgUp && (
        <IconButton onClick={onOpenNav}>
          <SvgColor src={MenuIcon} />
        </IconButton>
      )}

      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1 }}
      >
        {/* <SettingsButton /> */}
        <AccountPopover />
      </Stack>
    </>
  );
  return (
    <AppBar
      sx={{
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(lgUp && {
          width: `calc(100% - ${NAV.W_VERTICAL + 1}px)`,
          height: HEADER.H_DESKTOP,
          ...(offsetTop && {
            height: HEADER.H_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            height: HEADER.H_DESKTOP_OFFSET,
            borderBottom: `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_MINI + 1}px)`,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
};

export default Header;

import { createSlice } from '@reduxjs/toolkit'

const settings = {
    themeMode: "light", // 'light' | 'dark'
    themeDirection: "ltr", //  'rtl' | 'ltr'
    themeContrast: "default", // 'default' | 'bold'
    themeLayout: "vertical", // 'vertical' | 'horizontal' | 'mini'
    themeColorPresets: "default", // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
    themeStretch: false,
};

const initialState = {
    isLogin: false,
    user: null,
    role: null,
    userPrivileges: [],
    alertMessage: {
        show: false,
        message: '',
        severity: 'success',
    },
    defaultSettings: settings,
    themeSettings: settings
};

const slice = createSlice({
    name: "userInfo",
    initialState,
    reducers: {
        getUserDetails(state, action) {
            const allPrivileges = action.payload.apps?.flatMap(item => item.features.flatMap(feature => feature.privileges));
            state.isLogin = true;
            state.user = action.payload;
            state.role = action.payload.roleName;
            state.userPrivileges = allPrivileges;

        },
        setLogout(state, action) {
            state.isLogin = false;
            state.user = null;
        },

        showSnackbar: (state, action) => {
            state.alertMessage.show = true;
            state.alertMessage.message = action.payload.message;
            state.alertMessage.severity = action.payload?.severity || 'success';
        },
        hideSnackbar: (state) => {
            state.alertMessage.show = false;
        },
        updateSettings: (state, action) => {
            state.themeSettings = action.payload
        }

    },
    // extraReducers: (builder) => {
    //     builder.addCase('RESET_ALL', () => initialState);  // Reset to initial state
    // },

})

export default slice.reducer
export const {
    showSnackbar,
    hideSnackbar,
    getUserDetails,
    setLogout,
    updateSettings
} = slice.actions;




import { createSlice } from '@reduxjs/toolkit'

import client, { API_ENDPOINTS, setBaseUrl } from "../../services/apiClient"

const filters = {
    status: "all",
    search: '',
    sortOrder: '',
    sortColumn: '',
    page: 0,
    pageCount: 15,
}

const initialState = {
    defaultFilters: filters,
    projects: [],
    projectMeta: null,
    project: null,
    projectsCount: null,
    statics: {
        data: null,
        loading: false,
    },
    verticals: {
        data: null,
        loading: false
    },
    projectsStatus: {
        loading: false,
        empty: false,
        error: null,
    },
    projectStatus: {
        loading: false,
        error: null,
    },
    lookUp: {
        verticals: [],
        clients: [],
        projectManager: []
    }

}

const slice = createSlice({
    name: 'projects',
    initialState,
    reducers: {
        // GET PROJECTS
        getProjectsStart(state) {
            state.projectsStatus.loading = true;
            state.projectsStatus.empty = false;
            state.projectsStatus.error = null;
        },
        getProjectsFailure(state, action) {
            state.projectsStatus.loading = false;
            state.projectsStatus.empty = false;
            state.projectsStatus.error = action.payload;
        },
        getProjectsSuccess(state, action) {
            const projects = action.payload.records;
            state.projects = projects;
            state.projectMeta = action.payload.metadata;
            state.projectsStatus.loading = false;
            state.projectsStatus.empty = !projects.length;
            state.projectsStatus.error = null;
        },

        setSortingProjectData(state, action) {
            state.projects = action.payload;
        },

        // GET PROJECT COUNTS
        getProjectSCounts(state, action) {
            state.projectsCount = action.payload
        },

        // GET PROJECT STATICS
        getStaticsStart(state) {
            state.statics.loading = true;
        },

        getStaticsSuccess(state, action) {
            state.statics.data = action.payload;
            state.statics.loading = false;
        },
        getStaticsFailure(state) {
            state.statics.data = null;
            state.statics.loading = false;
        },


        // GET PROJECT VERTICALS
        getVerticalsStart(state) {
            state.verticals.loading = true;
        },
        getVerticalsSuccess(state, action) {
            state.verticals.data = action.payload;
            state.verticals.loading = false;
        },
        getVerticalsFailure(state) {
            state.verticals.data = null;
            state.verticals.loading = false;
        },
        getLookUps(state, action) {
            state.lookUp.clients = action.payload.client;
            state.lookUp.projectManager = action.payload.projectManager;
            state.lookUp.verticals = action.payload.vertical;
        },
        getVerticalLookUps(state, action) {
            state.lookUp.verticals = action.payload;
        },
        setFilterStatus(state, action) {
            state.defaultFilters = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase('RESET_ALL', () => initialState);  // Reset to initial state
    },

})


export default slice.reducer;

export const { setFilterStatus, setSortingProjectData } = slice.actions;


// ----------------------------------------------------------------------

// this function is used to get projects list
export function getProjectsList(sort, sortOrder, search, page, pageCount, status, vertical, clients, pm) {
    return async (dispatch) => {
        await setBaseUrl('invoice')
        try {
            dispatch(slice.actions.getProjectsStart());
            const response = await client.get(API_ENDPOINTS.projects.list(sort, sortOrder, search, page, pageCount, status, vertical, clients, pm))
            dispatch(slice.actions.getProjectsSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.getProjectsFailure(error));
        }
    };
}

export function getProjectsStatics() {
    return async (dispatch) => {
        await setBaseUrl('invoice')
        try {
            dispatch(slice.actions.getStaticsStart());
            const response = await client.get(API_ENDPOINTS.projects.staticsCount)
            if (response.ok) {
                dispatch(slice.actions.getStaticsSuccess(response.data.data))
            }
            else {
                dispatch(slice.actions.getStaticsFailure())
            }
        } catch (error) {
            dispatch(slice.actions.getStaticsFailure())
        }
    }
}

export function getProjectsVerticals() {
    return async (dispatch) => {
        try {
            await setBaseUrl('invoice')
            dispatch(slice.actions.getVerticalsStart());
            const response = await client.get(API_ENDPOINTS.projects.verticals)
            if (response.ok) {
                dispatch(slice.actions.getVerticalsSuccess(response.data.data))
            }
            else {
                dispatch(slice.actions.getVerticalsFailure())
            }
        } catch (error) {
            dispatch(slice.actions.getVerticalsFailure())
        }
    }
}

export function getProjectCounts() {
    return async (dispatch) => {
        try {
            await setBaseUrl('invoice')
            const response = await client.get(API_ENDPOINTS.projects.counts)
            if (response.ok) {
                dispatch(slice.actions.getProjectSCounts(response.data?.data));
            }
        } catch (error) {
            console.error(error);
        }
    };
}

export function getLookUpData(key) {
    return async (dispatch) => {
        await setBaseUrl('invoice')
        const response = await client.get(API_ENDPOINTS.getLookup(key));
        if (response.ok) {
            await dispatch(slice.actions.getLookUps(response.data.data))
        }
    }
};


export const getVerticalLookUp = (key) => {
    return async (dispatch) => {
        await setBaseUrl('invoice')
        const response = await client.get(API_ENDPOINTS.projects.verticalLookUp(key));
        if (response.ok) {
            await dispatch(slice.actions.getVerticalLookUps(response.data.data))
        }
    }
}

export const getProjectDetails = (code) =>
    client.get(API_ENDPOINTS.projects.details(code));

export const getProjectTable = (filters) => client.get(API_ENDPOINTS.projects.projectTable(filters))

export const postApproverUpdate = (data) => client.post(API_ENDPOINTS.projects.approval, JSON.stringify(data))

export const deleteClosedInvoiceMonth = (id) => client.delete(API_ENDPOINTS.projects.deleteInvoiceMonth(id))

export const closeInvoiceMonthLookup = (key) => client.get(API_ENDPOINTS.projects.getReasonsLookup(key))


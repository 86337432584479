import PropTypes from "prop-types";
import { useEffect, useCallback, useState } from "react";
import Cookies from 'js-cookie';
import { Auth } from "aws-amplify";
import { useLocation } from "react-router-dom";


// routes
import { useRouter } from "../../components/hook";
import { useAuthContext } from "../hooks";
import { useDispatch, useSelector } from "../../store/store";
import { setLogout } from "../../store/slices/useInfo";

// ----------------------------------------------------------------------

export default function AuthGuard({ children }) {
  const router = useRouter();
  const dispatch = useDispatch()
  const token = Cookies.get('token')
  const location = useLocation()

  const { isLogin } = useSelector(state => state.userInfo)

  const { authenticated } = useAuthContext();

  const [checked, setChecked] = useState(false);


  const check = useCallback(async () => {
    if (!isLogin) {
      localStorage.clear() // for caching need to clear storage
      dispatch({ type: 'RESET_ALL' });
      const searchParams = new URLSearchParams({ returnTo: window.location.href }).toString();
      //   const loginPath = loginPaths[method];
      const href = `/?${searchParams}`;
      router.replace(href);
    } else {
      setChecked(true);
      if (!token || typeof token === "undefined") {
        localStorage.clear()
        dispatch({ type: 'RESET_ALL' });
        dispatch(setLogout());
        Cookies.remove('token');
        await alert('Session has been expired please re-login again');
        await Auth.signOut()
        window.location.href = '/';
      }
    }
  }, [isLogin, router, token]);


  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };

  useEffect(() => {
    if (token) {
      const decodedJwt = parseJwt(token);

      if (decodedJwt.exp * 1000 < Date.now()) {
        console.log(decodedJwt.exp * 1000, Date.now())
        localStorage.clear()
        dispatch({ type: 'RESET_ALL' });
        dispatch(setLogout());
        Cookies.remove('token');
        alert('Session has been expired please re-login again');
        Auth.signOut()
        window.location.href = '/';
      }
    }
  }, [location]);


  useEffect(() => {
    check();
  }, [isLogin, token]);

  if (!checked) {
    return null;
  }

  return <>{children}</>;
}

AuthGuard.propTypes = {
  children: PropTypes.node,
};

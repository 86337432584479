import dayjs from "dayjs"
import { createSlice } from "@reduxjs/toolkit";
import client, { API_ENDPOINTS, setBaseUrl } from "../../services/apiClient";

const date = dayjs(new Date()).subtract(1, 'month');

const filters = {
    status: 'all',
    search: '',
    sortOrder: '',
    sortColumn: '',
    page: 0,
    pageCount: 15,
    vertical: [],
    project: [],
    client: [],
    // fromMonth: date.month() + 1,
    // fromYear: date.year(),
    currentDate: 'yearToDate',
    customDate: [null, null]
}

const initialState = {
    initialFilter: filters,
    defaultFilters: filters,
    invoice: [],
    invoiceMeta: null,
    invoiceStatus: {
        loading: false,
        empty: false,
        error: null,
    },
    invoiceProject: [],
    invoiceProjectMeta: null,
    invoiceProjectStatus: {
        loading: false,
        empty: false,
        error: null,
    },
    lookUp: {
        projectManagers: [],
        projects: [],
        clients: [],
        verticals: []
    },
    invoiceDashboard: [],
    invoiceDashboardStatus: {
        loading: false,
        error: null,
    },
    invoiceCount: null,

};

const slice = createSlice({
    name: "invoice",
    initialState,
    reducers: {
        // GET INVOICE LIST
        getInvoiceListStart(state) {
            state.invoiceStatus.loading = true;
            state.invoiceStatus.empty = false;
            state.invoiceStatus.error = null;
        },
        getInvoiceFailure(state, action) {
            state.invoiceStatus.loading = false;
            state.invoiceStatus.empty = false;
            state.invoiceStatus.error = action.payload;
        },
        getInvoiceSuccess(state, action) {
            const invoice = action.payload.records;
            state.invoice = invoice;
            state.invoiceMeta = action.payload.metadata;
            state.invoiceStatus.loading = false;
            state.invoiceStatus.empty = !invoice.length;
            state.invoiceStatus.error = null;
        },
        setSortingInvoiceData(state, action) {
            state.invoice = action.payload;
        },
        setSortingInvoiceProjectsData(state, action) {
            state.invoiceProject = action.payload;
        },

        getPendingInvoiceProjects(state, action) {
            state.invoiceProject = action.payload.records
            state.invoiceProjectMeta = action.payload.metadata
        },

        //get invoice dashboard data
        getInvoiceDashboardStart(state) {
            state.invoiceDashboardStatus.loading = true;
            state.invoiceDashboardStatus.error = null;
        },
        getInvoiceDashboardFailure(state, action) {
            state.invoiceDashboardStatus.loading = false;
            state.invoiceDashboardStatus.error = action.payload;
        },
        getInvoiceDashboardSuccess(state, action) {
            state.invoiceDashboard = action.payload;
            state.invoiceDashboardStatus.loading = false;
            state.invoiceDashboardStatus.error = null;
        },

        // GET INVOICE COUNTS
        getInvoiceCountsResponse(state, action) {
            state.invoiceCount = action.payload
        },

        getProjectLookupResponse(state, action) {
            state.lookUp.projects = action.payload
        },
        getClientLookupResponse(state, action) {
            state.lookUp.clients = action.payload
        },
        getVerticalLookupResponse(state, action) {
            state.lookUp.verticals = action.payload
        },
        getProjectManagerLookupResponse(state, action) {
            state.lookUp.projectManagers = action.payload
        },
        setFilters(state, action) {
            state.defaultFilters = { ...state.defaultFilters, ...action.payload };
        },
        setFilterStatus(state, action) {
            state.defaultFilters = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase('RESET_ALL', () => initialState);  // Reset to initial state
    },
});

export default slice.reducer;
export const { setFilterStatus, getPendingInvoiceProjects, setSortingInvoiceData, setSortingInvoiceProjectsData } = slice.actions;

// ----------------------------------------------------------------------

// this function is used to get invoice list
export function getInvoiceList(filters) {
    return async (dispatch) => {
        await setBaseUrl("invoice");
        dispatch(slice.actions.getInvoiceListStart());
        try {
            const response = await client.get(API_ENDPOINTS.invoice.list(filters));
            dispatch(slice.actions.getInvoiceSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.getInvoiceFailure(error));
        }
    };
}

export function getInvoiceDashboard(filter) {
    return async (dispatch) => {
        await setBaseUrl("invoice");
        dispatch(slice.actions.getInvoiceDashboardStart());
        try {
            const response = await client.get(API_ENDPOINTS.invoice.dashboardData(filter));
            dispatch(slice.actions.getInvoiceDashboardSuccess(response?.data?.data));
        } catch (error) {
            dispatch(slice.actions.getInvoiceDashboardFailure(error));
        }
    };
}

export function getInvoiceCounts() {
    return async (dispatch) => {
        try {
            await setBaseUrl('invoice')
            const response = await client.get(API_ENDPOINTS.invoice.statusCount)
            if (response.ok) {
                dispatch(slice.actions.getInvoiceCountsResponse(response?.data?.data));
            }
        } catch (error) {
            console.error(error);
        }
    };
}

export function getProjectsLookUp() {
    return async (dispatch) => {
        try {
            await setBaseUrl('invoice')
            const response = await client.get(API_ENDPOINTS.invoice.getProjects)
            if (response.ok) {
                dispatch(slice.actions.getProjectLookupResponse(response?.data?.data));
            }
        } catch (error) {
            console.error(error);
        }
    };
}

export function getClientsLookUp() {
    return async (dispatch) => {
        try {
            await setBaseUrl('invoice')
            const response = await client.get(API_ENDPOINTS.invoice.getClients)
            if (response.ok) {
                dispatch(slice.actions.getClientLookupResponse(response?.data?.data));
            }
        } catch (error) {
            console.error(error);
        }
    };
}

export function getVerticalsLookUp() {
    return async (dispatch) => {
        try {
            await setBaseUrl('invoice')
            const response = await client.get(API_ENDPOINTS.invoice.getVerticals)
            if (response.ok) {
                dispatch(slice.actions.getVerticalLookupResponse(response?.data?.data));
            }
        } catch (error) {
            console.error(error);
        }
    };
}

export function getProjectManagersLookUp() {
    return async (dispatch) => {
        try {
            await setBaseUrl('invoice')
            const response = await client.get(API_ENDPOINTS.invoice.getProjectManagers)
            if (response.ok) {
                dispatch(slice.actions.getProjectManagerLookupResponse(response?.data?.data));
            }
        } catch (error) {
            console.error(error);
        }
    };
}

export function getProjectsForInvoiceUpload(filters) {
    return async (dispatch) => {
        try {
            await setBaseUrl('invoice')
            const response = await client.get(API_ENDPOINTS.invoice.projectList(filters))
            if (response.ok) {
                dispatch(slice.actions.getPendingInvoiceProjects(response?.data?.data));
            }
        } catch (error) {
            console.error(error);
        }
    };
}

export const getInvoiceInfoById = (id, code) => client.get(API_ENDPOINTS.invoice.invoiceInfo(id, code))

export const getInvoiceApprovalStatus = (id) => client.get(API_ENDPOINTS.invoice.invoiceApprovalStatus(id));

export const getInvoiceProjectList = (filters) => client.get(API_ENDPOINTS.invoice.projectList(filters))

export const getProjectsLookUpByCode = (key, code) => client.get(API_ENDPOINTS.invoice.getProjectsLookUpByCode(key, code));

export const uploadInvoiceFile = (data) => client.post(API_ENDPOINTS.invoice.uploadFiles, data);

// export const uploadInvoiceFile = (data) => client.post(API_ENDPOINTS.invoice.uploadFiles, JSON.stringify(data));

export const getInvoiceApprovalUsers = (code) => client.get(API_ENDPOINTS.invoice.getApprovalUser(code))

export const getRevisionApprovalUsers = (invoiceID) => client.get(API_ENDPOINTS.invoice.getRevisionApprover(invoiceID))

export const getAdditionalApproverUsers = (code) => client.get(API_ENDPOINTS.invoice.getAdditionalUser(code))

export const putInvoiceApprovalStatus = (versionId, data) => client.put(API_ENDPOINTS.invoice.putApprovalStatus(versionId), JSON.stringify(data))

export const postSendInvoice = (invoiceId, projectCode, data) => client.post(API_ENDPOINTS.invoice.sendInvoice(invoiceId, projectCode), JSON.stringify(data))

export const putMarkAsPaid = (invoiceId, projectCode, data) => client.put(API_ENDPOINTS.invoice.markAsPaid(invoiceId, projectCode), JSON.stringify(data))

export const postRevisionInvoice = (data) => client.post(API_ENDPOINTS.invoice.revision, JSON.stringify(data))

export const GetDescription = () => client.get(API_ENDPOINTS.invoice.getInvoiceDescription)

export const postNotifyVH = (data) => client.post(API_ENDPOINTS.invoice.notifyVH, JSON.stringify(data))

export const postInvoiceCloseMonths = (data) => client.post(API_ENDPOINTS.invoice.closeMonth, JSON.stringify(data))


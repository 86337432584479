import PropTypes from "prop-types";
import isEqual from "lodash/isEqual";
import { useEffect, useMemo, useCallback, useState } from "react";
// hooks
// import { useLocalStorage } from 'src/hooks/use-local-storage';
// utils
// import { localStorageGetItem } from "src/utils/storage-available";
//
import { SettingsContext } from "./settings-context";
import { useDispatch, useSelector } from "../../../store/store";
import { updateSettings } from "../../../store/slices/useInfo";

// ----------------------------------------------------------------------

export function SettingsProvider({ children, defaultSettings }) {
  const dispatch = useDispatch()
  const [openDrawer, setOpenDrawer] = useState(false);
  const { themeSettings } = useSelector(state => state.userInfo)
  const [settings, setSettings] = useState(themeSettings);

  // const isArabic = localStorageGetItem('i18nextLng') === 'ar';

  // useEffect(() => {
  //   if (isArabic) {
  //     onChangeDirectionByLang('ar');
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isArabic]);

  useEffect(() => {
    dispatch(updateSettings(settings))
  }, [settings, dispatch])

  const onUpdate = useCallback((name, value) => {
    setSettings((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  // Direction by lang
  // const onChangeDirectionByLang = useCallback(
  //   (lang) => {
  //     onUpdate("themeDirection", lang === "ar" ? "rtl" : "ltr");
  //   },
  //   [onUpdate]
  // );

  // Reset
  const onReset = useCallback(() => {
    setSettings(defaultSettings);
    dispatch(updateSettings(defaultSettings))
  }, [defaultSettings, setSettings, dispatch]);

  // Drawer
  const onToggleDrawer = useCallback(() => {
    setOpenDrawer((prev) => !prev);
  }, []);

  const onCloseDrawer = useCallback(() => {
    setOpenDrawer(false);
  }, []);

  const canReset = !isEqual(settings, defaultSettings);

  const memoizedValue = useMemo(
    () => ({
      ...themeSettings,
      onUpdate,
      // Direction
      // onChangeDirectionByLang,
      // Reset
      canReset,
      onReset,
      // Drawer
      open: openDrawer,
      onToggle: onToggleDrawer,
      onClose: onCloseDrawer,
    }),
    [
      onReset,
      onUpdate,
      themeSettings,
      canReset,
      openDrawer,
      onCloseDrawer,
      onToggleDrawer,
      // onChangeDirectionByLang,
    ]
  );

  return (
    <SettingsContext.Provider value={memoizedValue}>
      {children}
    </SettingsContext.Provider>
  );
}

SettingsProvider.propTypes = {
  children: PropTypes.node,
  defaultSettings: PropTypes.object,
};

import PropTypes from "prop-types";
import { forwardRef } from "react";
// @mui
import { useTheme } from "@mui/material/styles";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
// routes
// import { RouterLink } from 'src/routes/components';
import { Link as RouterLink } from "react-router-dom";
import { useSettingsContext } from "../settings";

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const theme = useTheme();

  const settings = useSettingsContext();

  const mode = settings.themeMode;

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  const DART = theme.palette.common.black;

  const LIGHT = theme.palette.common.white;

  // OR using local (public folder)
  // -------------------------------------------------------
  // const logo = (
  //   <Box
  //     component="img"
  //     src="/logo/logo_single.svg" => your path
  //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
  //   />
  // );

  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: 170,
        // height: 100,
        display: "inline-flex",
        ...sx,
      }}
      {...other}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="504.76"
        height="53.769"
        viewBox="0 0 504.76 53.769"
      >
        <g id="Group_11" data-name="Group 11" transform="translate(2753 -152)">
          <path
            id="Path_1"
            data-name="Path 1"
            d="M312.87,43.54H301V35.429h32.9v8.109H322.038V77.03h-9.166Z"
            transform="translate(-3054 117.63)"
            fill={mode === "light" ? DART : LIGHT}
          />
          <path
            id="Path_2"
            data-name="Path 2"
            d="M322.6,35.43h9.166V50.94h17.8V35.43h9.166v41.6h-9.166V59.053h-17.8V77.041H322.6Z"
            transform="translate(-3035.953 117.631)"
            fill={mode === "light" ? DART : LIGHT}
          />
          <path
            id="Path_3"
            data-name="Path 3"
            d="M408.893,60.987c0,9.813-5.995,17.1-17.511,17.1-11.575,0-17.569-7.287-17.569-17.1V35.43h9.166V60.639a8.4,8.4,0,1,0,16.747,0V35.43h9.166Z"
            transform="translate(-2993.166 117.631)"
            fill={mode === "light" ? DART : LIGHT}
          />
          <path
            id="Path_4"
            data-name="Path 4"
            d="M423.217,35.43h9.166V50.94h17.8V35.43h9.161v41.6h-9.166V59.053h-17.8V77.041h-9.167Z"
            transform="translate(-2951.893 117.631)"
            fill={mode === "light" ? DART : LIGHT}
          />
          <path
            id="Path_5"
            data-name="Path 5"
            d="M458.444,43.54h-11.87V35.429h32.9v8.109H467.612V77.03h-9.166Z"
            transform="translate(-2932.375 117.63)"
            fill={mode === "light" ? DART : LIGHT}
          />
          <path
            id="Path_6"
            data-name="Path 6"
            d="M468.1,35.43h27.912v8.46H477.277v8.463h17.334v8.46H477.277V77.031H468.1Z"
            transform="translate(-2914.39 117.631)"
            fill={mode === "light" ? DART : LIGHT}
          />
          <path
            id="Path_7"
            data-name="Path 7"
            d="M573.128,60.987c0,9.813-5.995,17.1-17.511,17.1-11.564,0-17.569-7.287-17.569-17.1V35.43h9.166V60.639a8.4,8.4,0,1,0,16.747,0V35.43h9.166Z"
            transform="translate(-2855.949 117.631)"
            fill={mode === "light" ? DART : LIGHT}
          />
          <path
            id="Path_8"
            data-name="Path 8"
            d="M583.324,46.08a8.875,8.875,0,0,0-6.641-2.766c-2.584,0-6.173,1.177-6.173,4.291,0,7.579,19.157,2.7,19.157,17.277,0,9.28-7.463,13.689-16.042,13.689a19.779,19.779,0,0,1-13.985-5.229l6.525-7.158a10.187,10.187,0,0,0,7.992,3.937c3,0,6.343-1.411,6.343-4.348,0-7.643-19.506-3.526-19.506-17.4,0-8.869,7.7-13.509,15.866-13.509a18.808,18.808,0,0,1,12.749,4.348Z"
            transform="translate(-2837.908 117.148)"
            fill={mode === "light" ? DART : LIGHT}
          />
          <path
            id="Path_9"
            data-name="Path 9"
            d="M367.952,69.505a12.784,12.784,0,1,1,12.784-12.784,12.784,12.784,0,0,1-12.784,12.784m0-34.538A21.754,21.754,0,1,0,389.7,56.721a21.755,21.755,0,0,0-21.752-21.754"
            transform="translate(-3016.239 117.244)"
            fill={mode === "light" ? DART : LIGHT}
          />
          <path
            id="Path_10"
            data-name="Path 10"
            d="M508.25,69.505a12.784,12.784,0,1,1,12.784-12.784A12.784,12.784,0,0,1,508.25,69.505m0-34.538A21.754,21.754,0,1,0,530,56.721,21.754,21.754,0,0,0,508.25,34.967"
            transform="translate(-2899.02 117.244)"
            fill={PRIMARY_MAIN}
          />
          <path
            id="Path_11"
            data-name="Path 11"
            d="M516.559,63.626a5.806,5.806,0,1,0-5.806,5.806,5.806,5.806,0,0,0,5.806-5.806"
            transform="translate(-2883.604 136.338)"
            fill={PRIMARY_MAIN}
          />
          <path
            id="Path_12"
            data-name="Path 12"
            d="M419.373,34.967a22.345,22.345,0,0,1,16.259,6.8l-6.263,5.481a14.012,14.012,0,0,0-10-3.855,13.216,13.216,0,1,0,0,26.431,13.5,13.5,0,0,0,9.154-3.251V60.923H417.514v-8.19h19.693V69.719a22.21,22.21,0,0,1-17.826,8.553c-12.467,0-22.577-9.095-22.577-21.62s10.117-21.679,22.576-21.679"
            transform="translate(-2973.957 117.244)"
            fill={mode === "light" ? DART : LIGHT}
          />
          <path
            id="Path_13"
            data-name="Path 13"
            d="M535.761,34.967a22.392,22.392,0,0,1,17.07,7.867l-6.354,5.383a14.317,14.317,0,0,0-10.71-4.772,13.282,13.282,0,1,0,0,26.563,14.282,14.282,0,0,0,10.771-4.84l6.354,5.385a22.366,22.366,0,0,1-17.125,7.927c-12.525,0-22.69-9.137-22.69-21.723s10.165-21.782,22.69-21.782"
            transform="translate(-2876.813 117.244)"
            fill={mode === "light" ? DART : LIGHT}
          />
        </g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <Link component={RouterLink} to="/app/invoice" sx={{ display: "contents" }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default Logo;

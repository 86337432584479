import PropTypes from "prop-types";
import { useEffect, useCallback, useState } from "react";

import { useSelector } from "../../store/store";
import { useRouter } from "../../components/hook";

export default function AppGuard({ children }) {
    const router = useRouter();

    const { isLogin } = useSelector(state => state.userInfo)

    const checkIsLoginIn = useCallback(() => {
        if (isLogin) {
            router.replace('/app/invoice');
        }
        // {
        //     router.replace('/');
        // }
    }, [isLogin, router]);

    useEffect(() => {
        checkIsLoginIn();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isLogin) {
        return null;
    }

    return <>{children}</>;
}


AppGuard.propTypes = {
    children: PropTypes.node,
};